import store from '@/store';
import { supabase } from '@/supabase';
import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTasks(ctx, filters) {
      return new Promise((resolve, reject) => {

        let query = supabase.from("daily-task")
          .select(
            `*,
            profiles:user_id ( email )`
          ).eq("is_deleted", false);

        if (filters.user_id) { query.eq('user_id', filters.user_id) }
        if (filters.date) { query.eq('date', filters.date) }

        query.then(({ data, error }) => {
          if (error) {
            console.log('error in fetchTasks ', error);
            reject(error)
          }
          console.log(data);
          resolve(data)
        });
      })
    },
    addTask(ctx, taskData) {
      return new Promise((resolve, reject) => {
        taskData.user_id = store.user.id;
        delete taskData.id;
        supabase.from("daily-task").insert(taskData).then(({ data, error }) => {
          if (error) {
            console.log('Error in adding task ', error);
            reject()
          }
          resolve(data);
        });
      })
    },
    updateTask(ctx, { task }) {
      return new Promise((resolve, reject) => {
        let task_id = task.id;
        delete task.id;
        supabase.from("daily-task").update({ ...task }).eq('id', task_id).then(({ data, error }) => {
          if (error) {
            console.log('error in deleting task ', error);
            reject()
          }
          resolve(data);
        });
      })
    },
    removeTask(ctx, { id }) {
      return new Promise((resolve, reject) => {
        supabase.from("daily-task").update({ "is_deleted": true }).eq('id', id).then(({ data, error }) => {
          if (error) {
            console.log('error in deleting task ', error);
            reject()
          }
          resolve(data);
        });
      })
    },
  },
}
